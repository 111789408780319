<template>
  <div>
    <v-row id="gameheader" no-gutters>
      <v-col cols="12" sm="12" class="header_countdown_container">
        <v-row>
          <v-col cols="6">
            <div class="time">{{showTime ? showTime : display(difference)}}</div>
          </v-col>
          <v-col cols="6">
            <!--            <router-link to="/leaderboard">-->
            <div class="Leaderboard" @click="openLeaderboardModal()">
              <div class="leaderboard-image-style"></div>
              <span class="leaderboard-btn-title">{{ this.updateTeamData.rank }}</span>
            </div>
            <!--            </router-link>-->
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" class="header-container">
        <div class="points" :class="{'showTitle' : showTitle}">
          <div class="big-text">+{{ incrementedScoreDelta }}pts</div>
          <span>For {{ team.teamName }}</span>
        </div>
        <v-container class="grey lighten-5" style="font-weight:400;text-align:center">
          <div class="header-title-wrapper">
            <div @click="goBack" class="images-back"></div>
            <div class="game-page-header">
              <div class="playing-for-up">
                Playing for up to
                <span>${{ this.competitionInfo('totalDonations') }}</span> to support
              </div>
              <div class="game-page-title">{{ this.team.supporting }}</div>
            </div>
            <div class="thankYou-btn-container">
              <v-btn text class="thankYou-btn" @click="openThankYouPopup()">T</v-btn>
            </div>
            <div class="gift-img-container">
              <v-btn text class="gift-btn" @click="openSurveyPopup()"></v-btn>
            </div>
            <div class="leaderboard-img-container">
              <v-btn text class="leaderboard-button" @click="openLeaderboardModal()" v-on="on"></v-btn>
            </div>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-row no-gutters class="game-content">
      <v-col cols="12" sm="12">
        <v-row justify="center">
          <v-dialog v-model="showLeaderBoard" scrollable>
            <v-card>
              <v-card-title>
                <div class="lead-header">
                  <h3 class="lead-h3">Leaderboard</h3>
                  <v-btn
                      icon
                      dark
                      @click="closeLeaderboard()"
                      color="#535bb6"
                      style="font-size:20px"
                  >
                    X
                  </v-btn>
                </div>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 100%;">
                <LeaderboardBody/>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-dialog>
        </v-row>
        <AddToHomeScreen/>

        <div id="gameiframe" class="game-container" v-if="iframe"></div>
      </v-col>
      <v-row justify="center" class="lider-dialog w-100">
        <v-dialog
            v-model="leaderboardModal"
            width="100%"
            fullscreen hide-overlay
        >
          <div class="final_result_container" :class="{'top-margin': showArrow}" v-if="showArrow">
            <div class="final_title">Final Result &nbsp;
            </div>

          </div>
          <v-card class="leader-content">
            <v-tabs
                v-model="tab"
                centered
                class="all-tabs"
                :class="{'leader-content_margin': showArrow}"
            >
              <div class="back-arrow">
                <div @click="goBack" class="go-back" :class="{'showArrow' : showArrow}"></div>
              </div>
              <div class="lider-tabs-items">
                <v-tab
                    v-for="item in items"
                    :key="item.tab"
                >
                  {{ item.tab }}
                </v-tab>
              </div>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item
                  v-for="item in items"
                  :key="item.tab"
              >
                <v-card flat class="all-leader-items">
                  <v-card-text
                      v-for="(i, index) in item.content"
                      :key="index"
                  >
                    <div :class="{'active': i.teamName === activeItem || i.nickname === activeNickName}" class="leader-item">
                      <span>{{ index + 1 + '.' }} {{ i.teamName ? i.teamName : i.nickname}}</span>
                      <span>{{ (i.score ? i.score : i.highscore || "0").toLocaleString() }}pts</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-dialog>
      </v-row>
    </v-row>
    <ThankYouPopup :openThankYou="openThankYou" @closeThankYou="openThankYou = false"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import EventBus from "../event-bus";
import LeaderboardBody from "../components/LeaderboardBody.vue";
import AddToHomeScreen from "../components/AddToHomeScreen.vue";
import ThankYouPopup from "../components/ThankYouPopup";

export default {
  data() {
    return {
      on: "",
      dialog: false,
      incrementedScoreDelta: "",
      showTitle: false,
      showLeaderBoard: false,
      language: "he",
      openThankYou: false,
      leaderboardModal: false,
      tab: null,
      difference: 0,
      showTime: 0,
      activeItem: '',
      activeNickName: '',
      highscore: 0,
      iframe: true,
      showArrow: false,
      items: [
        {
          tab: 'Teams',
          content: []
        },
        {
          tab: 'Highscore',
          content: []
        },
      ],
    };
  },
  components: {
    ThankYouPopup,
    LeaderboardBody,
    AddToHomeScreen,
  },
  computed: {
    ...mapState(["team", "competition", "userId", "leaderboard", "tournamentData", "nickName"]),
    updateTeamData: function () {
      let postfix = ["st", "nd", "rd"];
      if (!this.leaderboard) return {};
      for (let i = 0; i < this.leaderboard.length; i++) {
        if (this.leaderboard[i].teamId === this.$store.state.teamId) {
          let rank = i + 1;
          if (rank > 3) {
            return {rank: rank + "th", score: this.leaderboard[i].score};
          }
          return {rank: rank + postfix[i], score: this.leaderboard[i].score};
        }
      }
      return {};
    }
  },
  watch: {
    difference: {
      handler(value) {
        if (value >= 0) {
          setTimeout(() => {
            this.difference--;
          }, 1000);
        } else {
          this.openLeaderboardModal()
          this.showArrow        = true
          this.iframe           = false;
          setTimeout(() => {
            this.iframe = true
          }, 0)
        }
      },
      immediate: false // This ensures the watcher is triggered upon creation
    },
  },
  mounted: function () {
    let endDate     = Math.floor(Date.parse(this.$store.state.tournamentEnd));
    let currentTime = Math.floor(Date.parse(this.now)) || Math.floor(Date.parse(new Date()));
    this.difference = (endDate - currentTime) / 1000;
    window.history.replaceState(
        {},
        "FunFunding",
        `/${this.$store.state.shortId}`
        // `/?teamId=5f744759d2b24a092c902122&refUserId=5eaae246a3c518000770bfc9`
    );
    window.mixpanel.track("gamepage-view", {
      f_action_guid: window.funfunding.uuidv4()
    });
    const gameId = this.$route.params.gameId;

    this.initGame(gameId);
    let self = this;
    EventBus.$on("new_personal_score", function (payLoad) {
      self.openSharepopup(payLoad.showPopup, {f_level: payLoad.currentLevel});
      if (!payLoad.incrementedScoreDelta) return;
      self.showPointsTitle(`${payLoad.incrementedScoreDelta}`, 4000);
      if (payLoad.showInspirationPopup) {
        window.mixpanel.track("gamepage-inspiration-show", {
          f_quoteId: payLoad.inspirationQuoteIndex,
          f_action_guid: window.funfunding.uuidv4()
        });
      }
    });
    this.interval = setInterval(function () {
      self.$forceUpdate();
    }, 1000);
    this.items[0].content = this.leaderboard;
    this.activeItem = this.team.teamName
  },

  beforeDestroy: function () {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    competitionInfo: function (field) {
      if (!this.competition) return null;
      return this.competition[field];
    },
    goBack() {
      this.showLeaderBoard  = false;
      this.leaderboardModal = false;
      window.mixpanel.track("gamepage-leaderboard-close-click", {
        f_action_guid: window.funfunding.uuidv4()
      });
    },
    closeLeaderboard() {
      this.showLeaderBoard = false;
      window.mixpanel.track("gamepage-leaderboard-close-click", {
        f_action_guid: window.funfunding.uuidv4()
      });
    },
    openLeaderboard() {
      this.showLeaderBoard = true;
      window.mixpanel.track("gamepage-leaderboard-click", {
        f_action_guid: window.funfunding.uuidv4()
      });
    },
    openThankYouPopup() {
      this.openThankYou = true;
      window.mixpanel.track("gamepage-thankyoupopup-click", {
        f_action_guid: window.funfunding.uuidv4()
      });
    },
    openSharepopup(flag, data) {
      this.dialog = flag;
      if (this.dialog) {
        window.mixpanel.track("sharepopup-show", {
          data: data,
          f_action_guid: window.funfunding.uuidv4()
        });
      }
    },
    demoAddScore() {
      this.$store.dispatch("demoAddScore");
    },
    openSurveyPopup() {
      window.mixpanel.track("gamepage-pollfish-click", {
        f_game: window.client.gameId,
        f_action_guid: window.funfunding.uuidv4()
      });
      // Pollfish.showIndicator or Pollfish.showFullSurvey
      window.Pollfish.showFullSurvey();
    },
    close() {
      this.dialog = false;
      window.mixpanel.track("sharepopup-close-click", {
        f_action_guid: window.funfunding.uuidv4()
      });
    },
    showPointsTitle(points, expiryTime = 4000) {
      this.incrementedScoreDelta = points;
      this.showTitle             = true;
      setTimeout(() => (this.showTitle = false), expiryTime);
    },
    initGame: function (gameId) {
      if (this.globalIframe) return;

      //////// 1) Create the iframe that will contains the game ////////
      const iframe = document.createElement("iframe");
      iframe.id    = "game-frame";
      iframe.src   = `https://play.gamepix.com/${gameId}/embed?sid=O15NN`;
      iframe.setAttribute("frameborder", "1");
      iframe.setAttribute("name", window.location.href);
      iframe.setAttribute("width", "100%");
      let header_h = document.getElementById("gameheader").clientHeight;
      iframe.setAttribute("height", `${window.innerHeight - header_h}px`);
      iframe.setAttribute("scrolling", "yes");
      // iframe.style.top = '0%';
      // iframe.style.left = '0%';
      document.getElementById("gameiframe").appendChild(iframe);
      window.gameIframe = iframe;

      window.client        = window.client || {};
      window.client.gameId = gameId;

      // this.showPointsTitle(500000, 20000);
    },
    display(seconds) {
      const format = val => `0${Math.floor(val)}`.slice(-2)
      const d      = Math.floor(seconds / (3600 * 24));
      const h      = Math.floor(seconds % (3600 * 24) / 3600);
      const m      = Math.floor(seconds % 3600 / 60);
      const s      = Math.floor(seconds % 60);
      return d + 'd ' + [h, m, s].map(format).join(':')
    },
    openLeaderboardModal() {
      this.leaderboardModal = true
      this.$store.dispatch("loadHighScores", this.$store.state.tournamentData._id).then(() => {
        this.items[1].content = this.$store.state.highscores.sort((a, b) => (b.highscore) - (a.highscore));
      });
      this.items[0].content = this.$store.state.leaderboard
      this.showHighscore()
      this.activeNickName = this.nickName
    },
    showHighscore() {
      this.$store.dispatch('getLeaderboardRequest1', this.team._id).then(() => {
        for(let item of this.$store.state.leaderboard) {
          if(item.teamName === this.activeItem) {
            this.highscore = item.score
          }
        }
      })
    },
  },
};
</script>

<style scoped>
.showArrow {
  display: none;
}

body{
  overflow: hidden !important;
}
.active {
  background-color: #535BB6;
  border: 1px solid #535BB6;
  border-radius: 8px;
}
.header_countdown_container {
  width: 100%;
  height: 70px;
  background-color: #2D325F;
  position: absolute;
  top: 0;
  z-index: 2;
  transform: translateY(0px);
}

.Leaderboard {
  border-radius: 48px;
  background-color: #535BB6;
  font-family: Fredoka One;
  font-size: 16px;
  color: #ffffff;
  padding: 5px 10px;
  margin-top: 6px;
  margin-right: 12px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: 0.4s;
  width: 120px;
  float: right;
}

.Leaderboard:hover {
  background-color: #5A78D4;
}

.leaderboard-image-style {
  background-image: url("../assets/images/leaderboard.png");
  width: 16px;
  height: 17px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}

.leaderboard-btn-title {
  vertical-align: middle;
  margin-left: 5px;
}

.time {
  font-family: Fredoka One;
  font-size: 24px;
  line-height: 1;
  color: #fff;
  margin-top: 8px;
  margin-left: 16px;
}

.lider-dialog {
  width: 100%;
  height: 100%;
}

.back-arrow {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 16px !important;
}

.final_result_container {
  width: 100%;
  height: 50px;
  background-size: cover;
  background-color: #373E7B;
  background-image: url('../assets/images/final_results_background_1.png');
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 5;
  margin-bottom: 50px !important;
}
.final_result_container .final_title {
  text-transform: capitalize;
  font-family: Fredoka One;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #fff !important;
}
.leader-content_margin {
  margin-top: 50px;
}
.leader-content {
  background-color: #373E7B;
  overflow: auto;
}

.lider-tabs-items {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.lider-tabs-items .v-tab {
  text-transform: capitalize;
  font-family: Fredoka One;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #88BDF4 !important;
}

.lider-tabs-items .v-tab.v-tab--active {
  color: white;
}

.leader-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 0 8px;
}


.all-tabs {
  background-color: #2D325F;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.all-leader-items {
  background-color: #373E7B !important;
  border-radius: 0 !important;
}

.all-leader-items .v-card__text {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Fredoka One;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 46px;
  padding: 5px 10px;
}

.leaderboard-button {
  background-image: url("../assets/images/leaderboard-color.png");
}

.v-dialog > .v-card > .v-card__text {
  padding: 0px;
}

.close-button {
  color: black;
}

.lead-h3 {
  padding-left: 20px;
  color: #535bb6;
  font-family: Fredoka One;
  font-size: 24px;
  font-weight: 500;
}

.lead-header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}

.game-content {
  display: flex;
  justify-content: center;
}

.leader-button {
  position: absolute;
  top: 55px;
  width: 120px;
  color: #535bb6;
  background-color: aliceblue;
  margin-right: 5px;
  border-radius: 5px;
  font-weight: 600;
  z-index: 9;
  visibility: visible;
  opacity: 1;
  transition: 0.4s;
  outline-style: none;
}

.header-container {
  overflow: hidden;
}

.points {
  width: 100%;
  height: 66px;
  position: absolute;
  top: 0;
  z-index: 99;
  background-image: url("../assets/images/Group_3.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-100%);
  transition: 0.4s;
}

.showTitle {
  transform: translateY(0);
}

.big-text {
  height: 53px;
  position: absolute;
  top: 0;
  color: #535bb6;
  font-family: Fredoka One;
  font-size: 38px;
}

.points span {
  color: #424242;
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  top: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.v-dialog__content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.popup {
  width: 335px;
  height: 345px;
  border: solid 3px #808dd3 !important;
  background-color: #535bb6 !important;
  position: absolute;
  border-radius: 14px !important;
  box-shadow: none;
  left: 0;
  right: 2%;
  top: 7%;
  margin: 0 auto;
}

.star {
  background-image: url("../assets/images/pop.png");
  width: 95px;
  height: 95px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-size: cover;
  position: absolute;
  top: -17px;
  left: -10px;
}

.close {
  background-image: url("../assets/images/close_modal.png");
  width: 40px;
  height: 40px;
  background-size: cover;
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;
}

.invite-friends-icon {
  width: 35px;
  height: 38px;
  object-fit: contain;
  background-image: url("../assets/images/invite_friends_icon.png");
  position: relative;
  top: 50px;
  margin: 0 auto;
}

.playing-for {
  width: 149px;
  height: 41px;
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  position: relative;
  top: 56px;
  margin: 0 auto;
}

.invite-your-friends {
  width: 230px;
  height: 66px;
  font-family: Fredoka One;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  position: relative;
  margin: 0 auto;
  top: 66px;
}

.climb-up {
  width: 191px;
  height: 54px;
  font-family: Fredoka One;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  position: relative;
  margin: 0 auto;
  top: 65px;
  margin-bottom: 5px;
}

.invite-friends-btn {
  background-image: url("../assets/images/button.png");
  position: relative;
  margin: 0 auto;
  top: 60px;
  width: 210px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.invite-btn-text {
  width: 149px;
  height: 23px;
  font-family: Fredoka One;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  position: relative;
}

.iframe {
  width: 100%;
  height: 528px;
  border: none;
}

.trigger-showing-temporary-title {
  position: absolute;
  width: 120px;
  top: 40px;
  right: 0;
  color: #535bb6;
  background-color: aliceblue;
  margin-right: 5px;
  border-radius: 5px;
  font-weight: 600;
  z-index: 9;
  visibility: visible;
  opacity: 1;
  transition: 0.4s;
  outline-style: none;
}

.header-title-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
}

.images-back {
  background-image: url("../assets/images/back.png");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 13px;
  height: 22px;
  min-width: 13px;
  cursor: pointer;
}

.go-back {
  background-image: url("../assets/images/back.png");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 13px;
  height: 22px;
  min-width: 13px;
  cursor: pointer;
}

.game-page-header {
  width: 80%;
  text-align: left;
  padding-left: 10px;
}

.leaderboard-img-container {
  width: 10%;
}

.leaderboard-img {
  background-image: url("../assets/images/leaderboard-color.png");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 32px;
  height: 30px;
}

.images-back-link {
  width: 9%;
}

.playing-for-up {
  font-family: Montserrat;
  font-size: 12px;
  line-height: 1;
  color: #999;
}

.playing-for-up > span {
  font-weight: bold;
  color: #5a78d4;
}

.game-page-title {
  font-family: Fredoka One;
  font-size: 20px;
  color: #535bb6;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.game-container {
  display: flex;
  justify-content: center;
  background-color: #000;
}

.temporary-header-hide {
  transform: translateY(100%);
  visibility: hidden;
  opacity: 0;
}

.gift-btn {
  background-image: url("../assets/images/offer.png");
}

.gift-img-container {
  width: 29px;
  margin: 4px 17px 0 0;
}

.inspire-btn {
  font-size: 12px;
  font-family: Fredoka One;
  color: #535bb6;
  min-width: 50px !important;
  padding: 0 13px !important;
  margin-left: 3px;
}

.thankYou-btn-container {
  width: 8%;
  padding-left: 10px;
  display: flex;
  justify-content: center;

}

.thankYou-btn {
  font-size: 14px;
  font-family: Fredoka One;
  color: #535bb6;
  min-width: 30px !important;
  padding: 0 5px !important;
}
/*@media (min-width: 600px) {*/
/*  .top-margin {*/
/*    width: 97.8%;*/
/*  }*/
/*}*/
@media (min-width: 700px) {
  .leader-content_margin{
    margin-top: 77px;
  }
  .top-margin {
    height: 80px;
  }
}
/*@media (min-width: 850px) {*/
/*  .top-margin{*/
/*    width: 98.5%;*/
/*  }*/
/*}*/
/*@media (min-width: 1150px) {*/
/*  .top-margin {*/
/*    width: 99.2%;*/
/*  }*/
/*}*/
</style>
