<template>
  <div>
    <div>
      <div class="tournament-container">
        <v-row class="tournament-wrapper" :class="{'visibility': dialog}">
          <div class="header-content">
            <h4>{{companyName}}</h4>
            <h2>{{teamName}}</h2>
          </div>
          <div class="tournament-content">
            <div class="beat-title">
              Ready to beat
              your colleagues?
            </div>
            <div class="game-img">
              <img src="https://games.assets.gamepix.com/40223/thumbnail/small.png" alt="">
            </div>
            <div class="tournament-start">
              Tournament {{ start }}
            </div>
            <div class="button-container">
              <v-btn rounded :disabled="!buttonVisible" class="green-btn" :class="{'play-btn': buttonVisible}"
                     @click="playNow()">{{ timeString ? timeString : this.display(timerCount)}}
              </v-btn>
            </div>
          </div>
          <div class="powered">
            <span class="power">Powered by </span>&nbsp;<span class="fun"> FunFunding</span>
          </div>
        </v-row>
        <v-row justify="center" class="dialog-wrapper w-100">
          <v-dialog
              v-model="dialog"
              width="100%"
              fullscreen hide-overlay
          >
            <v-card class="all-dialog-content">
              <div class="dialog-border">
                <div class="wrapper-vector">
                  <h4>Let them know you</h4>
                  <h2>Choose a Nickname</h2>
                </div>
                <div class="nickNameError" v-if="nickError">
                  <span>{{nickError}}. <br> Please choose a different nickname.</span>
                </div>
                <v-text-field placeholder="Type here" class="input-field" v-model="nickName" ></v-text-field>
                <v-btn rounded :disabled="!start_btn_visible" class="letsStart-btn"  @click="letsStart()">Let's start</v-btn>
              </div>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Main-page",
  data() {
    return {
      // seconds for countdown timer
      timerCount: 5,
      timeString: '',
      buttonVisible: false,
      start_btn_visible: false,
      start: 'starts in',
      dialog: false,
      teamName: '',
      companyName: '',
      nickName: '',
      teamId: '',
      shortId: '',
      nickError: '',
      games: [
        {
          gameId: "tower-mania",
          imageSrc: "https://games.assets.gamepix.com/40323/thumbnail/small.png"
        },
        {
          gameId: "yeti-sensation",
          imageSrc: "https://games.assets.gamepix.com/40301/thumbnail/small.png"
        },
        {
          gameId: "tentrix",
          imageSrc: "https://games.assets.gamepix.com/40264/thumbnail/small.png"
        },
        {
          gameId: "baseball-hero",
          imageSrc: "https://games.assets.gamepix.com/40406/thumbnail/small.png"
        },
        {
          gameId: "bouncy-dunk",
          // imageSrc: "./img/bouncydunk.png",
          imageSrc: "https://games.assets.gamepix.com/40407/thumbnail/small.png"
        },
        {
          gameId: "color-pin",
          imageSrc: "https://games.assets.gamepix.com/40309/thumbnail/small.png"
        }
      ]
    };
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.timeString    = 'Play now'
          this.buttonVisible = true
          this.start         = 'is live'
        }
      },
      immediate: false // This ensures the watcher is triggered upon creation
    },
    nickName: {
      handler(value) {
        this.start_btn_visible = value !== '';
      }
    }
  },
  async created() {
    this.shortId = this.$route.params.shortId;
    await this.$store.dispatch('getTeamId', this.shortId);
    this.teamId = this.$store.state.teamId;
    this.teamName = this.$store.state.teamName;
    this.$store.dispatch('getLeaderboardRequest1', this.$store.state.teamId).then((res) => {
      this.companyName = res.tournament.company;
      this.getTeamData()
    })
  },

  methods: {
    display(seconds) {
      const format  = val => `0${Math.floor(val)}`.slice(-2)
      const hours   = seconds / 3600
      const minutes = (seconds % 3600) / 60

      return [hours, minutes, seconds % 60].map(format).join(':')
    },
    async playNow() {
      await this.$store.dispatch('getNickName', {userId: this.userId})
        if(this.$store.state.nickName === '' || !this.$store.state.nickName) {
            this.dialog = true
        } else {
            this.letsStart()
        }

    },
    letsStart() {
        if(this.$store.state.nickName === '' || !this.$store.state.nickName) {
          this.$store.dispatch('setNickName', {userId: this.userId, nickname: this.nickName}).then((res) => {
            if (res.error) {
              this.nickError = res.error
            } else {
              this.dialog = false;
              this.$store.dispatch('getNickName', {userId: this.userId})
              this.nickName = this.$store.state.nickName
              this.$router.push(`/game/monster-blocks`);
            }
          })
        }
    },
    clickRouterLink(gameId) {
      window.mixpanel.track("mainpage-game-click", {
        f_game: gameId,
        f_action_guid: window.funfunding.uuidv4()
      });
      return true;
    },
    contactUs() {
      let link = "https://brands-rousers.org/funfunding";
      window.mixpanel.track("mainpage-contactus-click", {
        f_link: link,
        f_action_guid: window.funfunding.uuidv4()
      });
      window.open(link, "_blank");
    },
    openSupportingWebsite() {
      let link = this.teamInfo("supportingUrl");
      // link = "https://brands-rousers.org/";
      window.mixpanel.track("mainpage-ngowebsite-click", {
        f_link: link,
        f_action_guid: window.funfunding.uuidv4()
      });
      window.open(link, "_blank");
    },
    timeLeft() {
      if (
          !this.$store.state.competition ||
          !this.$store.state.competition.endTime
      ) {
        return null;
      }

      const endDate     = new Date(this.$store.state.competition.endTime);
      const currentDate = this.now || new Date();
      if (currentDate > endDate) {
        return "Competition Done";
      }
      let diff  = endDate.getTime() - currentDate.getTime();
      let parts = [86400000, 3600000, 60000];
      let vis   = parts.map(p => {
        let result = Math.floor(diff / p);
        diff -= result * p;
        return result;
      });
      vis.push(Math.floor(diff / 1000));
      let ui = "";
      parts  = [
        {postfix: "d ", min_len: -1},
        {postfix: ":", min_len: -2},
        {postfix: ":", min_len: -2},
        {postfix: "", min_len: -2}
      ];
      for (let i = 0; i < vis.length; i++) {
        let part = parts[i];
        ui += `0${vis[i]}`.slice(part.min_len) + part.postfix;
      }
      return ui;
    },
    inviteClick: function () {
      if (navigator.share) {
        // Web Share API is supported
        window.mixpanel.track("mainpage-invitefriends-click", {
          f_success: true,
          f_action_guid: window.funfunding.uuidv4()
        });
        // this.$ma.trackEvent({
        //   action: "mainpage-invitefriends-click",
        //   success: true
        // });
        navigator
            .share({
              title: "Share FunFunding with friends",
              url: document.location.href
            })
            .then(() => {
              console.log("Thanks for sharing!");
            })
            .catch(console.error);
      } else {
        // Fallback
        window.mixpanel.track("mainpage-invitefriends-click", {
          f_success: false,
          f_action_guid: window.funfunding.uuidv4()
        });
        // this.$ma.trackEvent({
        //   action: "mainpage-invitefriends-click",
        //   success: false
        // });
        alert("Please share the page url from the web browser");
      }
    },
    teamInfo: function (field) {
      if (!this.team) return null;
      return this.team[field];
    },
    competitionInfo: function (field) {
      if (!this.competition) return null;
      return this.competition[field];
    },
    requireLocalImage: function (imageSrc) {
      return require(`../assets/${imageSrc}`);
    },
     getTeamData() {
      let currentDate = this.now || new Date();
       currentDate = +Math.floor(Date.parse(currentDate)) / 1000
       this.timerCount = +(Math.floor(Date.parse(localStorage.getItem('startTournament'))) / 1000) - currentDate
       // this.timerCount = '4'
    }
  },

  computed: {
    ...mapState(["team", "competition", "userId", "leaderboard", "tournamentData"]),
    updateTeamData: function () {
      let postfix = ["st", "nd", "rd"];
      if (!this.leaderboard) return {};
      for (let i = 0; i < this.leaderboard.length; i++) {
        if (this.leaderboard[i].teamId === this.team._id) {
          let rank = i + 1;
          if (rank > 3) {
            return {rank: rank + "th", score: this.leaderboard[i].score};
          }
          return {rank: rank + postfix[i], score: this.leaderboard[i].score};
        }
      }
      return {};
    }
  },
  mounted: function () {

    if (Object.prototype.hasOwnProperty.call(this.$route.query, "inspireId")) {
      window.mixpanel.track("mainpage-inspiration-show", {
        quoteId: this.$route.query.inspireId,
        f_action_guid: window.funfunding.uuidv4()
      });
      this.$refs.inspireMainDialog.show(this.$route.query.inspireId);
    }
    if (this.team && this.team._id) {
      window.history.replaceState(
          {},
          "FunFunding",
          `/${this.shortId}`);
      window.mixpanel.track("mainpage-view", {
        f_action_guid: window.funfunding.uuidv4()
      });
    }
    if(!this.$route.params.shortId) {
      this.timerCount = 0;
      this.buttonVisible = false
    } else {
      var self      = this;
      this.interval = setInterval(function () {
        self.$forceUpdate();
      }, 1000);
    }
  },
  beforeDestroy: function () {
    clearInterval(this.interval);
    this.interval = null;
  },

};
</script>

<style scoped>
.dialog-border .v-input.input-field {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.nickNameError {
  position: absolute;
  bottom: 36px;
  width: 202px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  font-size: 12px;
  background-color: #FF405C;
  justify-content: center;
  color: #fff;
}
.nickNameError span {
  text-align: center;
}

/*tournament style*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



.tournament-container {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/Rectangle.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.tournament-wrapper {
  width: 100%;
  height: 20%;
  background-image: url("../assets/images/Vector.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}

.tournament-wrapper:last-child {
  align-content: end;
}

.header-content {
  width: 100%;
  height: 100%;
  text-align: center;
}

.header-content h4 {
  margin-top: 15px;
  color: #88BDF4;
  font-family: Fredoka One,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
}

.header-content h2 {
  color: #fff;
  font-family: Fredoka One,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
}

.tournament-content {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #fff;
  flex-direction: column;
}

.beat-title {
  font-family: Fredoka One,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 1.6;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0 15px 20px 15px;
}

.game-img {
  width: 100%;
  height: 130px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.game-img img {
  background-position: center;
  width: 135px;
  height: 100%;
}

.tournament-start {
  font-family: Fredoka One,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
}

.button-container .v-btn--disabled {
  color: #fff !important;
}

.green-btn {
  background: linear-gradient(180deg, #88CB55 47.92%, #79C043 51.04%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 48px;
  width: 240px !important;
  height: 60px !important;
  font-family: Fredoka One,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 44px;
  color: #fff !important;
}

.powered {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
}

.powered .power {
  font-family: Montserrat,serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
  color: #88BDF4;
}

.powered .fun {
  font-family: Fredoka One,serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.5px;
}

.play-btn {
  font-size: 30px !important;
  text-transform: inherit;
}

.visibility {
  visibility: hidden;
}

.dialog-wrapper {
  width: 100%;
  background-color: transparent;
}

.all-dialog-content {
  background-color: transparent;
  border-radius: 0;
  display: flex;
  justify-content: center;
  background: transparent !important;
}

.letsStart-btn {
  background: linear-gradient(180deg, #88CB55 47.92%, #79C043 51.04%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 48px;
  width: 240px !important;
  height: 60px !important;
  font-family: Fredoka One,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px !important;
  line-height: 44px;
  color: #fff;
  text-transform: inherit;
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.dialog-border {
  width: 100%;
  height: 315px;
  max-width: 295px;
  background: #1E004E;
  border: 3px solid #6A74E4;
  border-radius: 15px !important;
  position: relative;
  margin-top: 25px;
}

.wrapper-vector {
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/Vector1.png");
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
  padding-top: 15px;
}

.wrapper-vector h4 {
  color: #88BDF4;
  font-family: Fredoka One,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
}

.wrapper-vector h2 {
  color: #fff;
  font-family: Fredoka One,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  margin-top: 5px;
}

.input-field {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 70%;
  font-family: Fredoka One,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 100%;
  display: flex;
  align-items: center !important;
  text-align: center !important;
  color: #fff !important;
}


</style>
